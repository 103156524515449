@mixin reset-small-margin {
	@include breakpoint($breakpoint-extra down) {
		@include xy-gutters($gutters: 0px, $negative: false);
	}
}

@mixin transition-delay($nth: 1, $items: 5, $initial: 0, $step: 0.1) {
	@for $i from $nth through $items {
		&:nth-of-type(#{$i}){
			transition-delay: #{$initial}s;
		}

		$initial: $initial + $step;
	}
}

.label-line {
	display: inline-block;
	position: relative;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 16px;

	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #555;
	}

	&::before {
		top: -3px;
	}

	&::after {
		bottom: -3px;
	}
}

@include breakpoint(medium down) {
  .footer-wrapper {
    margin-bottom: 30px; // TR-993
  }
}