.quick-view-modal {
    min-height: 560px;

    .modal-header {
        padding-bottom: 6px;
    }

    .modal-content {
        padding: 0 25px 40px 25px;

        @include breakpoint(medium down) {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .product-quickview {
        &::after {
            content: '';
            position: absolute;
            visibility: hidden;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            opacity: 0;
            transition: visibility 0.3s ease, opacity 0.3s ease;
        }

        &.loading {
            &::after {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .align {
        width: 100%;
        padding-top: 20px;
    }

    .slides {
        position: relative;
        overflow: hidden;
    }
}

.quick-view-images {
    overflow: hidden;

    @include breakpoint(medium down) {
        margin-top: 20px;
    }

    .owl-stage {
        @include clearfix;
    }

    .owl-item {
        float: left;
    }

    .owl-dots {
        display: none;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        width: 38px;
        height: 38px;
        top: 50%;
        font-size: rem-calc(28);
        background-color: $secondary-color;
        color: $black;
        margin-top: -19px;
        transition: opacity 0.3s ease;

        &.disabled {
            opacity: 0.5;
            cursor: default;
        }

        &::after {
            content: '';
            position: absolute;
            margin-top: -4px;
        }
    }

    .owl-prev {
        left: 0;

        &::after {
            @include arrow(9px, 9px, black, 2px, left);
            left: 50%;
            margin-left: -4px;
        }
    }

    .owl-next {
        right: 0;

        &::after {
            @include arrow(9px, 9px, black, 2px, right);
            right: 50%;
            margin-right: -4px;
        }
    }

    img {
        width: 100%;
        transition: opacity 0.3s ease;

        &.loading {
            opacity: 0.5;
        }
    }
}

.quick-view-details {
    flex-wrap: wrap;
    position: relative;

    .full-pdp-link {
        width: 100%;
        text-align: right;
        margin-top: auto;
        padding-top: 20px;

        .pdp-link {
            display: inline-block;
            vertical-align: middle;
            transition: color 0.3s ease;
        }
    }

    .new-arrival {
        font-size: 12px;
    }

    .wishlist-social {
        width: 16px;
        height: 16px;

        svg {
            position: relative;
            width: 16px;
            height: 16px;
            fill: currentColor;
            transition: visibility 0.3s ease, opacity 0.3s ease;
        }

        .add-to-wish-list {
            position: relative;
            color: #000;
        }

        .wishlist-add {
            position: absolute;
            visibility: hidden;
            top: -1px;
            left: 0;
            opacity: 0;
        }
    }

    .add-to-wish-list {
        &.is-in-wishlist {
            .wishlist-empty {
                visibility: hidden;
                opacity: 0;
            }

            .wishlist-add {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .product-name-group {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #666666;
    }

    .label-line {
        font-size: 12px;
    }

    .product-name {
        display: block;
        width: calc(100% - 16px);
        font-size: rem-calc(20);
        font-weight: 500;
        line-height: 1.2;
        color: $black;
        padding-right: 20px;
    }

    .price {
        line-height: 1.2;

        .strike-through {
            display: inline-block;
            margin-right: 4px;
        }

        .value {
            font-size: rem-calc(20);
        }

        .range.range-discount{
            .label-range-discount{
                margin-bottom: 0;
                margin-right: 5px;
            }
        }
    }

    [data-attr="size"] {
        margin-top: 15px;
    }

    .attribute {
        overflow: hidden;

        .color,
        .size {
            display: block;
            font-weight: 300;
            line-height: 1;
            margin-bottom: 12px;
        }

        .color-name {
            font-weight: 500;
        }

        .size {
            a {
                display: none;
            }
        }
    }

    .color-cell-container {
        @include xy-grid;
        margin-left: -10px;
        margin-right: -10px;

        .swatch-color {
            position: relative;
            width: calc(14% - 20px);
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 12px;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #000;
                transform: scale3d(0, 0, 0);
                transition: transform 0.45s ease;
                transform-origin: left;
            }

            &.selected {
                cursor: default;

                &::after {
                    transform: scale3d(1, 1, 1);
                }
            }

            &.unselectable {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        .swatch-value {
            display: block;
            min-height: 26px;
            margin-bottom: 3px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

    .quantity-select {
        margin: 0;
    }

    .list-size {
        @include xy-grid;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 0;

        > li {
            position: relative;
            width: calc(14.26% - 20px);
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
            text-align: center;
            line-height: 1;
            backface-visibility: hidden;
            cursor: pointer;
            white-space: nowrap;

            &[disabled] {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &:not([disabled]) {
                font-weight: 500;
            }

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #000;
                transform: scale3d(0, 0, 0);
                transition: transform 0.45s ease;
                transform-origin: left;
            }

            &.selected {
                cursor: default;

                &::after {
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    }

    .size-unavailable {
        margin-bottom: 28px;

        .description {
            font-size: 14px;
            line-height: 1;
            margin-bottom: 0;
        }

        a {
            color: #000;
            text-decoration: underline;
        }
    }

    .error-msg {
        color: #C82828;
    }

    .cart-and-ipay {
        margin-top: 10px;
    }

    .add-to-cart {
        margin-bottom: 0;

        &.disabled:not([disabled]) {
            color: rgba(white, 0.6);
            cursor: default;

            &:hover {
                color: rgba(white, 0.6);
            }
        }
    }

    .find-in-boutique {
        margin-top: 10px;

        .button {
            margin-bottom: 0;
        }
    }

	.availability-msg {
		font-weight: 500;
		color: #C82828;
	}
}
